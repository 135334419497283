/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages

        // Object Fit Polyfill
        if ($(".ie").length || $('.edge').length) {

          $('.people-row__avatar-img').each(function () {

            var image = $(this).attr('src');

            $(this).attr('style', 'background-image:url(' + image + ');  background-size: cover; background-position: 50%; color:rgba(0,0,0,0);').attr('src', '/wp-content/themes/stafford-rosenbaum/dist/img/clear.gif');

          });
        }

        /*
         *
         *
         *  Our People - Filter Nav
         *
         *
         */
        (function () {
          var FILTER_NAV_ITEMS = $('.wism-filter-nav__link');
          var FILTER_NAV_TOGGLES = $('.js-filter-nav-toggle');

          FILTER_NAV_TOGGLES.on('click', function (evt) {
            console.log('filter nav toggle clicked');
            evt.preventDefault();
            var filterName = $(this).data('filter-name');
            console.log(filterName);

            FILTER_NAV_ITEMS.removeClass('wism-filter-nav__link--active');
            $(this).addClass('wism-filter-nav__link--active');

            $('.wism-filter-bar__item').removeClass('wism-filter-bar__item--active');
            $('.wism-filter-bar__item[data-filter-name="' + filterName + '"]').addClass('wism-filter-bar__item--active');
          });
        }());
        /*
         *
         *
         *  SELECT2 - General
         *
         *
         */
        (function () {
          /*
           *
           *
           *  Select2 Init
           *
           *
           */

          $('.select2').select2();
          // console.log('123')

        }());

        /*
         *
         *
         *  SELECT2 GLOBAL SEARCH
         *
         *
         */
        (function () {

          /*
           *
           *
           *  Filter array of posts by search term.
           *
           *
           */
          var filterByTerm = function (array, term) {
            return array.filter(function (item) {
              // console.log(item.text.toLowerCase(), params.term, item.text.toLowerCase().includes(params.term));
              if (item.text.toLowerCase().includes(term.toLowerCase())) {
                var result = {
                  'id': item.id,
                  'text': item.text,
                  'url': item.url
                };
                // console.log(result);
                return result;
              }
            });
          }

          /*
           *
           *
           *  Split the data into groups by the "type" key.
           *   - this allows select2 to create headings in the results set
           *
           *
           */
          var splitIntoGroups = function (data) {
            var groupedResults = [];
            var formattedResults = [];

            for (var i = 0; i < data.length; i++) {
              console.log(data[i]['type']);
              if (!Array.isArray(groupedResults[data[i]['type']])) {
                groupedResults[data[i]['type']] = [];
              }
              groupedResults[data[i]['type']].push(data[i]);
              // console.log(data[i]['type']);
            }

            if (Array.isArray(groupedResults.practices) && groupedResults.practices.length) {
              formattedResults.push({
                text: 'Practices',
                children: groupedResults.practices
              });
            }

            // if (Array.isArray(groupedResults['practice-group']) && groupedResults['practice-group'].length) {
            //   formattedResults.push({
            //     text: 'Practice Groups',
            //     children: groupedResults['practice-group']
            //   });
            // }

            if (Array.isArray(groupedResults.offices) && groupedResults.offices.length) {
              formattedResults.push({
                text: 'Offices',
                children: groupedResults['offices']
              });
            }

            if (Array.isArray(groupedResults.staff) && groupedResults.staff.length) {
              formattedResults.push({
                text: 'Staff',
                children: groupedResults['staff']
              });
            }

            if (Array.isArray(groupedResults.posts) && groupedResults.posts.length) {
              formattedResults.push({
                text: 'Press & Events',
                children: groupedResults['posts']
              });
            }

            if (Array.isArray(groupedResults.page) && groupedResults.page.length) {
              formattedResults.push({
                text: 'Pages',
                children: groupedResults['page']
              });
            }

            return formattedResults;
          }

          /*
           *
           *
           *  Global Search Init
           *
           *
           */
          var GlobalSearch = $('.wism-global-search .select2').select2({
            ajax: {
              dataType: 'json',
              url: OldFashioned.wism_api_route + 'global-search/',
              //delay: 0,
              cache: true,
              data: function (params) {
                // console.log('');
                // console.log('Data callback');
                // console.log(params);

                return {
                  q: params.term // search term
                };
              },
              processResults: function (data, params) {

                // console.log('');
                // console.log('ProcessResults');
                // console.log(data, params);
                // console.log('');

                if (params.term && params.term.length) {
                  data = filterByTerm(data, params.term);
                }

                data = splitIntoGroups(data);

                console.log(data);
                $('body').addClass('search-active')

                return {
                  results: data
                };
              },
            },
            // templateSelection: function(item){
            //     console.log('');
            //     console.log('Template selection:');
            //     console.log(item);
            //     // return 'Searching: ' + '';
            //     console.log('');
            // },
            templateResult: function (item) {
              //   var markup = "<div class='wism-seleect2-result'>";
              //           markup += "<a class='wism-select2-result__link' href='" + item.url + "'>";
              //               markup += item.text;
              //           markup += "</a>";
              //       markup += "</div>";
              // $('body').addClass('search-active')

              if (item.loading) return item.text;

              var wrapper = $("<div />");
              wrapper.addClass('wism-select2-result');

              var link = $('<a />');
              link.addClass('wism-select2-result__link');
              link.attr('href', item.url);
              link.html(item.text);

              wrapper.html(link);

              var markup = wrapper;

              return markup;

            }
          });

          var globalSearchHandler = function (event) {
            if (!event.target) return false;
            var newLocation = event.target.value;
            window.location.href = newLocation;
            // console.log(event.target);
          }

          GlobalSearch.on('change', globalSearchHandler);

          GlobalSearch.on('select2:close', function () {

            $('body').removeClass('search-active').removeClass('global');

            // return false;
          });

          GlobalSearch.on('select2:opening', function () {

            $('body').addClass('global');

          });
          GlobalSearch.on('select2:open', function () {

          });

          $(document).on('keyup', function (e) {
            if ((e.which == 13 || e.keyCode == 13) && $('body').hasClass('search-active')) {
              var search = $('.select2-search__field').val();

              console.log(search);
              if (search != null) {

                window.location.href = '/?s=' + search;
              }
            }

          });

          // $(document).on('click', function(evt){
          //     evt.stopPropagation();
          //     console.log('link Clciked...');
          //     console.log($(this));
          //     // window.location.href = evt.target.href;
          // });

        }());

        /*
         *
         *
         *  Mobile menu toggle
         *
         *
         */
        (function () {

          var MENU_TOGGLE = $('.menu-toggle');
          var MOBILE_MENU = $('.mobile-nav');
          var upOneLevelTargets = $('.up-one-level');
          var mobileNavTopLevel = $('.mobile-nav__top-level');

          // $(document).on('click', function(evt){
          //     return false;
          //     console.log(evt);
          // });

          $(document).on('click touchend', '.nav-drawer-toggle', navDrawerToggleHandler);

          function navDrawerToggleHandler(evt) {

            evt.stopPropagation();
            if (!evt.target.dataset.navTarget) {
              console.error('Invalid nav toggle event -- missing target nav drawer');
              return false;
            }
            var navTarget = evt.target.dataset.navTarget;
            switchDrawers(navTarget);
            return false;
          }

          function showTopLevel() {
            mobileNavTopLevel.addClass('in');
          }

          function scrollInnerNavToTop() {
            var MOBILE_INNER = $('.mobile-nav__inner')[0];
            MOBILE_MENU[0].scrollTop = 0;
            MOBILE_INNER.scrollTop = 0;
          }

          function hideDrawers(evt) {
            hideTopLevel();
            $('.mobile-nav__drawer').each(function (i, el) {
              $(el).removeClass('in');
            });
          }

          function switchDrawers(drawerId) {
            var drawer = $('.mobile-nav__drawer[data-nav-drawer="' + drawerId + '"]');
            hideDrawers();
            hideTopLevel();
            scrollInnerNavToTop();
            drawer.addClass('in');
          }

          function showTopLevel() {
            mobileNavTopLevel.addClass('in');
          }

          function hideTopLevel() {
            mobileNavTopLevel.removeClass('in');
          }

          upOneLevelTargets.on('click touchend', function (evt) {

            evt.preventDefault();

            var targetMenu = $(this).data('returnMenu');
            hideDrawers();

            switch (targetMenu) {
            case 'top-level':
              showTopLevel();
              break;
            case 'practice-groups-root':
              switchDrawers('practice-groups-root');
              break;
            default:
              switchDrawers(targetMenu);
              break;
            }

          });

          MENU_TOGGLE.on('click', function (evt) {

            evt.preventDefault();
            hideDrawers();
            if (MENU_TOGGLE.hasClass('menu-toggle--active')) {
              // text = menu
              MENU_TOGGLE.removeClass('menu-toggle--active');
              MENU_TOGGLE.find('.menu-toggle__text').html('Menu');

            } else {
              // text = menu
              MENU_TOGGLE.addClass('menu-toggle--active');
              MENU_TOGGLE.find('.menu-toggle__text').html('Close');

            }
            if ($('body').hasClass('mobile-nav--active')) {
              $('body').removeClass('mobile-nav--active');

            } else {
              $('body').addClass('mobile-nav--active');
              showTopLevel();

            }

          });

        }());

        /*
         *
         *
         *  Recognition Carousel
         *
         *
         */
        (function () {

          // JavaScript to be fired on the home page
          $('.recognition-carousel').slick({
            arrows: false,
            dots: true,
            autoplay: true,
            autoplaySpeed: 4000,
            accessibility: false
          });

        }());

        /*
         *
         *
         *  Find a Professional Widget
         *
         *
         */
        (function () {

          var FAP_TABS = $('.fap__tabs__item');
          var FAP_TABS_LINKS = $('.fap__tabs__link');
          const FAP_TABS_ACTIVE_CLASS = 'fap__tabs__item--active';
          const FAP_TABS_SLIDES = $('.fap__tabs__slide');

          const switchTabs = function (evt) {
            var targetSlideID = $(this).data('fap-toggle');
            var targetSlide = $('.fap__tabs__slide[data-fap-slide="' + targetSlideID + '"]')
            var parentTab = $(this).closest('.fap__tabs__item');
            evt.preventDefault();
            FAP_TABS_SLIDES.hide();
            targetSlide.show();
            FAP_TABS.removeClass(FAP_TABS_ACTIVE_CLASS);
            parentTab.addClass(FAP_TABS_ACTIVE_CLASS);
          }

          FAP_TABS_LINKS.on('click', switchTabs);

        }());

        /*
         *
         *
         *  Select2 - Auto Fill
         *
         *
         */
        (function () {

          // https://stackoverflow.com/questions/5448545/how-to-retrieve-get-parameters-from-javascript
          function findGetParameter(parameterName) {
            var result = null,
              tmp = [];
            location.search
              .substr(1)
              .split("&")
              .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
              });
            return result;
          }

          const QUERIED_PRACTICE_GROUP = findGetParameter('taxonomy');
          const QUERIED_PRACTICE = findGetParameter('practice');
          const QUERIED_OFFICE = findGetParameter('office');
          const QUERIED_POSITION = findGetParameter('pos');

          const PRACTICES_GROUP_DROPDOWN = $('.advanced-search-wrapper select.select2[name="taxonomy"]');
          const PRACTICES_DROPDOWN = $('.advanced-search-wrapper select.select2[name="practice"]');
          const OFFICES_DROPDOWN = $('.advanced-search-wrapper select.select2[name="office"]');
          const POSITIONS_DROPDOWN = $('.advanced-search-wrapper select.select2[name="pos"]');

          if (QUERIED_PRACTICE_GROUP && PRACTICES_GROUP_DROPDOWN.length) {
            PRACTICES_GROUP_DROPDOWN.val(QUERIED_PRACTICE_GROUP).trigger('change');

            console.log('searched for ' + QUERIED_PRACTICE_GROUP);
          }
          if (QUERIED_PRACTICE && PRACTICES_DROPDOWN.length) {
            //PRACTICES_DROPDOWN.val(QUERIED_PRACTICE).trigger('change');

            console.log('searched for ' + QUERIED_PRACTICE);

          }
          if (QUERIED_OFFICE && OFFICES_DROPDOWN.length) {
            OFFICES_DROPDOWN.val(QUERIED_OFFICE).trigger('change');
          }
          if (QUERIED_POSITION && POSITIONS_DROPDOWN.length) {
            POSITIONS_DROPDOWN.val(QUERIED_POSITION).trigger('change');
          }

        }());

        /*
         *
         *
         *  Find a Professional Widget
         *
         *
         */
        (function () {

          const ACTIVE_CLASSES = {
            item: 'wism-tabs__item--active',
            contentItem: 'wism-tabs__content-item--active'
          }

          const WISM_TABS = {
            items: $('.wism-tabs__item a').not('.no-tab'),
            contentItems: $('.wism-tabs__content-item'),
          }

          const switchTabs = function (evt) {

            var targetTabID = $(this).data('tab-id');
            var targetTab = $('.wism-tabs__content-item[data-tab-id="' + targetTabID + '"]');

            evt.preventDefault();

            var offsetHeight = $('header').outerHeight();

            $('html, body').animate({
              scrollTop: $('#bio').offset().top - offsetHeight - 32
            }, 0);

            WISM_TABS.contentItems.hide();
            targetTab.show();
            $('.wism-tabs__item').removeClass(ACTIVE_CLASSES.item);
            $('.wism-tabs__item a').removeClass(ACTIVE_CLASSES.item);
            $(this).addClass(ACTIVE_CLASSES.item);
          }

          WISM_TABS.items.on('click', switchTabs);

        }());

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $('.socials-list li a').click(function (e) {

          e.preventDefault();
          var poplink = $(this).attr('href');
          newwindow = window.open(poplink, 'name', 'height=800,width=1024');
          if (window.focus) {
            newwindow.focus()
          }
          return false;

        });

        $(".wpcf7-form").find("p").contents().unwrap();

        $(".wpcf7 label").each(function () {

          var label = $(this).text();

          $(this).find('input').attr('placeholder', label);

        });

        $('.client-list > li').click(function () {

          $(this).toggleClass('active');
          $(this).children('ul').slideToggle(300);

        });

        var header = $('header').outerHeight();

        $('body').attr('style', '--top:' + header + 'px');

        window.addEventListener("orientationchange", function () {

          setTimeout(function () {
            var header = $('header').outerHeight();

            $('body').attr('style', '--top:' + header + 'px');
          }, 300);

        });

        $(window).resize(function () {
          if ($(window).width() < 1025) {

            var header = $('header').outerHeight();

            $('body').attr('style', '--top:' + header + 'px');

          }

        });

        if (/iPhone/i.test(navigator.userAgent)) {
          var disableScroll = false;
          var scrollPos = 0;

          function stopScroll() {
            disableScroll = true;
            scrollPos = $(window).scrollTop();

            console.log('scroll stopped');
          }

          function enableScroll() {
            disableScroll = false;

            console.log('scroll enabled');

          }
          $(function () {
            $(window).bind('scroll', function () {
              if (disableScroll) $(window).scrollTop(scrollPos);
            });
            $(window).bind('touchmove', function () {
              $(window).trigger('scroll');
            });
          });

          $('.select2').on('select2:open', function (evt) {

            $('body').addClass('mobile-search-active');

            //                            stopScroll();
            if ($('.ui-min').length) {

              $('html, body').animate({
                scrollTop: $('#returnTop').offset().top
              }, 0);

            }

            //                     var header = $('header').outerHeight();
            //
            //                     $('.select2-container').css('top', header);

            //                            $('.mobile-nav').slideUp(0);

            //                            $('html, body').animate({
            //                                scrollTop: $('#returnTop').offset().top
            //                            }, 0);

            //                            $('body').css('padding-top', '0');
            // Do something
          });
          $('.select2').on('select2:close', function (evt) {

            $('body').removeClass('mobile-search-active');

            //                            enableScroll();

            //                            $('.mobile-nav').css('position', '');
            // Do something
          });

          $(window).scroll(function () {

            var windowfull = $.windowHeight();
            var windowHeight = $(window).height(),
              remaining = windowfull - windowHeight;

            console.log(windowfull);
            console.log(windowHeight);
            console.log(remaining);

            if (windowfull !== windowHeight) {

              $('body').addClass('ui-min');

            } else if (remaining == 0) {
              $('body').removeClass('ui-min');

            }

          });

        }

      }
    }, // Home page
    'home': {
      init: function () {

        /*
         *
         *
         *  Home Carousel
         *
         *
         */
        (function () {

          const TAP_TO_VIEW_OVERLAY = $('.tap-to-view-overlay');
          const HERO_SECTION_CAROUSEL = $('.hero-section-carousel');

          TAP_TO_VIEW_OVERLAY.on('click touch', function () {
            $(this).hide()
          });

          HERO_SECTION_CAROUSEL.slick({
            arrows: false,
            dots: false,
            autoplay: true,
            autoplaySpeed: 4000,
            pauseOnHover: true,
            fade: true,
            accessibility: false
          });

        }());

      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS

      }
    }, // About us page, note the change from about-us to about_us.
    'single_careers': {
      init: function () {
        // JavaScript to be fired on the about us page

        $('select[name="apply-to-location"] option').attr('disabled', 'disabled');

        $('.available-loc li').each(function () {

          var location = $(this).text();

          $('select[name="apply-to-location"] option[value="' + location + '"]').attr('disabled', false);

        });
      }
    }, // About us page, note the change from about-us to about_us.
    'page_id_51': {
      init: function () {
        // JavaScript to be fired on the about us page

      }
    },
    'tax_practice_group': {
      init: function () {
        $('.practices-section__item.has-content').on('click', function () {
          $(this).toggleClass('active');
        });
      }
    },
    'single_staff': {
      finalize: function () {
        $('.badges center > p').each(function () {
          if ($(this).children().length == 0) {
            $(this).hide();
          }
        });
      }
    }
  };
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';
      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');
      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });
      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
