/* ========================================================================
 * Findsome & Winmore Team Switcher
 * ======================================================================== */

(function ($) {

    var WISMLocationMap = function () {

        var site_shell = $('.site-shell');
        var internal = {
            mapSelector: 'locations-map',
            config: {
                desktopWidth: 1030,
                markers: {
                    active: '/wp-content/themes/staffordlaw/dist/img/icons/map-marker-active.svg',
                    normal: '/wp-content/themes/staffordlaw/dist/img/icons/map-marker.svg'
                }
            }
        };

        internal.colors = {
            primary: "#082A4A",
            secondary: "#c7c8ca",
            tertiary: "#ccbc72",
            transparent: "rgba(255,255,255,0)"
        }

        internal.places = {
            mobileCenter: {
                lat: 28.316342, lng: -83.735864
            },
            florida: {
                lat: 27.664827, lng: -81.515754
            },
            westOfFlorida: {
                lat: 27.872466, lng: -85.561523
            }
        }

        internal.infoBubbleDefaultSettings = {
          shadowStyle: false,
        //   padding: 20,
        //   backgroundColor: 'rgb(57,57,57)',
          borderRadius: 0,
          arrowSize: 0,
          borderWidth: 0,
          disableAutoPan: true,
          hideCloseButton: false,
          disableAnimation: true,
          backgroundClassName: 'location-window__inner',
          backgroundColor: 'rgba(255,255,255,0)',
          arrowStyle: 0,
          wrapperClass: 'location-window',
          heightOffset: -50
        }

        // The infobubble needs to be "passed around" between marker to marker with content injected depending on the marker clicked
        // -- this is easier than trying to close them all every time a new one is clicked.
        internal.infoBubble = new InfoBubble(internal.infoBubbleDefaultSettings);

        internal.getMapColor = function(key){
            var hexValue = false;
            switch(key){
                case 'water':
                    if($('body').hasClass('post-type-archive-locations')){
                        hexValue = "#ffffff"
                    } else {
                        hexValue = internal.colors.primary
                    }
                    break;
            }
            return hexValue;
        }

        internal.map_styles = [
            {
                "featureType": "administrative",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": internal.colors.tertiary
                    },
                    {
                        "saturation": "0"
                    },
                    {
                        "gamma": "1"
                    }
                ]
            },
            {
                "featureType": "landscape.natural.terrain",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": internal.colors.tertiary
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    },
                    {
                        "invert_lightness": true
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    },
                    {
                        "color": internal.colors.tertiary
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#f7eded"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#FFAD00"
                    },
                    {
                        "saturation": -19.8
                    },
                    {
                        "lightness": -1.8
                    },
                    {
                        "gamma": 1
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#ffad00"
                    },
                    {
                        "saturation": 72.4
                    },
                    {
                        "lightness": -32.6
                    },
                    {
                        "gamma": 1
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": 74.4
                    },
                    {
                        "lightness": -18
                    },
                    {
                        "gamma": 1
                    },
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#5f5a5a"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -63.2
                    },
                    {
                        "lightness": 38
                    },
                    {
                        "gamma": 1
                    },
                    {
                        "color": internal.getMapColor('water')
                    },
                    {
                        "weight": "0.01"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "saturation": "45"
                    },
                    {
                        "lightness": "64"
                    },
                    {
                        "color": "#6c6c6c"
                    },
                    {
                        "weight": "1.59"
                    }
                ]
            }
        ];

        internal.mapOptions = {};

        internal.defaultMapOptions = {
          zoom: 6.5,
          styles: internal.map_styles,
          disableDefaultUI: true,
          padding: 10,
          draggable: false,
          scrollwheel: false,
          zoomControl: false,
          backgroundColor: internal.colors.primary
        };

        internal.desktopMapOptions = {
            gestureHandling: 'none',
            center: internal.places.westOfFlorida,
            zoom: 7,
            minZoom: 7,
            maxZoom: 7,
            width: 500
        }

        internal.mobileMapOptions = {
            gestureHandling:  'cooperative',
            center:  internal.places.mobileCenter,
            zoom:  6,
            minZoom:  4,
            maxZoom:  8
        }

        internal.mergeResponsiveOptions = function(options, screenWidth){
            var newOptions = {}
            // if the screen is wider than the width set up in the config actions
            if(screenWidth >= internal.config.desktopWidth){
                newOptions = $.extend(options, internal.desktopMapOptions);
            } else {
                newOptions = $.extend(options, internal.mobileMapOptions);
            }
            return newOptions;
        }

        internal.locations = [];

        internal.getLocations = function(){
            $.ajax({
              dataType: "json",
              url: OldFashioned.wism_api_route + 'locations/',
              success: function(data){
                  internal.locations = data.locations;

                  internal.markers = internal.createMarkers(internal.locations, internal.map)
              }
            });
        }


        internal.createMarkers = function (locations, map) {

            var i = 0;

            var locationMarkers = locations.map(function(office){

                var name = office.post_title;
                var lat = office.map.lat;
                var lng = office.map.lng;

                var marker = new google.maps.Marker({
                  position: {lat: parseFloat(lat), lng: parseFloat(lng)},
                  map: map,
                  icon: internal.config.markers.normal
                });

                internal.addInfoBubbleListener(marker, map, office);

                i++;

                return marker;

            });

            return locationMarkers;





        };

        internal.resetMarkerIcons = function(markers){
            for (var i = 0; i < internal.markers.length; i++) {
               internal.markers[i].setIcon(internal.config.markers.normal);
            }
        }

        internal.getInfoBubbleContent = function(office){

            var rand = Math.random() * 100;

            var content = '';
                content += "<div class='location-window__content'>";
                content +=    "<div class='row'>";
                content +=        "<div class='col-lg-5'>";
                if(office.featured_image){
                    content +=            "<img class='location-window__image' src='" + office.featured_image + "' />";
                } else {
                    content +=            "<img class='location-window__image' src='" + OldFashioned.stylesheet_directory + "/dist/img/no-image-small.svg' />";
                }
                content +=        "</div>";
                content +=        "<div class='col-lg-7'>";
                if(office.post_title){
                    content +=         "<a class='location-window__title' href='" + office.permalink + "'>" + office.post_title + "</a>";
                }
                if(office.phone){
                    content +=         "<a class='location-window__phone' href='" + office.permalink + "'><i class='location-window__icon'></i><strong>Phone:</strong> " + office.phone + "</a>";
                }
                if(office.fax){
                    content +=         "<a class='location-window__phone' href='" + office.permalink + "'><i class='location-window__icon'></i><strong>Fax:</strong> " + office.fax + "</a>";
                }
                 content +=            "<a class='location-window__btn' href='" + office.permalink + "'>View Location &rarr;</a>";
                content +=        "</div>";
                content +=    "</div>";
                content += "</div>";
            return content;
        }

        internal.addInfoBubbleListener = function(marker, map, office){

            // update content of info bubble
            var infoBubble = internal.infoBubble;

            // Click Events for InfoBubble
            google.maps.event.addListener(marker, 'click', function(evt) {

                internal.hideCarouselText(evt);
                internal.resetMarkerIcons(internal.markers);

                marker.setIcon(internal.config.markers.active);

                // close any open infoBubbles
                internal.infoBubble.close();
                // set new content for the single infoBubble
                if(marker.position.lat() >= 28.23){
                    infoBubble.setWrapperClass([
                        'location-window',
                        'location-window--below'
                    ]);
                    infoBubble.setHeightOffset(250);
                } else {
                    infoBubble.setWrapperClass([
                        'location-window'
                    ]);
                    infoBubble.setHeightOffset(internal.infoBubbleDefaultSettings.heightOffset);
                }
                infoBubble.setContent(internal.getInfoBubbleContent(office))
                // open the infoBubble
                infoBubble.open(map, marker);

            });

        }

        internal.hideCarouselText = function(evt){
            $('.hero-section-carousel__item--has-map .tap-to-view-overlay').fadeOut(200);
        }

        internal.addCloseListener = function(){

            // Click Events for InfoBubble
            google.maps.event.addListener(internal.infoBubble, 'closeclick', function(evt) {

                internal.resetMarkerIcons();

            });

        }



        this.init = function () {

            internal.getLocations();

            internal.addCloseListener();

            var screenWidth = $(window).width();

            internal.mapOptions = internal.mergeResponsiveOptions(internal.defaultMapOptions, screenWidth);

            internal.map = new google.maps.Map(
                document.getElementById(internal.mapSelector), // Selector for the map
                internal.defaultMapOptions // Default set of map options
            );;

            google.maps.event.addDomListener(window, "resize", function() {
                var center = internal.map.getCenter();
                google.maps.event.trigger(internal.map, "resize");
                internal.map.setCenter(center);
            });
        }

    }

    jQuery(document).ready(function(){

        var LocationMap = new WISMLocationMap();

        if($('#locations-map').length){
            LocationMap.init();
        }

    });
//
})(jQuery); // Fully reference jQuery after this point.
