jQuery(document).ready(function ($) {

  /*
   *
   *
   *  Filter array of posts by search term.
   *
   *
   */
  var filterByTerm = function (array, term) {
    return array.filter(function (item) {
      // console.log(item.text.toLowerCase(), term, item.text.toLowerCase().includes(term));
      if (item.text.toLowerCase().includes(term.toLowerCase())) {
        var result = {
          'id': item.id,
          'text': item.text,
          'url': item.url
        };
        // console.log(result);
        return result;
      }
    });
  }

  /*
   *
   *
   *  Practice Search Init
   *
   *
   */
  var PracticeSearch = $('#practice-search').select2({
    ajax: {
      dataType: 'json',
      url: OldFashioned.wism_api_route + 'select2/practices/',
      delay: 50,
      cache: true,
      data: function (params) {
        // console.log('');
        // console.log('Data callback');
        // console.log(params);
        // console.log('');
        return {
          q: params.term // search term
        };
      },
      processResults: function (data, params) {

        data = data.results;

        if (params.term && params.term.length) {
          data = filterByTerm(data, params.term);
          // console.log(data);
        }

        return {
          results: data
        };
      },
    },

    templateResult: function (item) {

      if (item.loading) return item.text;

      var wrapper = $("<div />");
      wrapper.addClass('wism-select2-result');

      var link = $('<a />');
      link.addClass('wism-select2-result__link');
      link.attr('href', item.url);
      link.html(item.text);

      wrapper.html(link);

      var markup = wrapper;

      return markup;
    }
  })

  var practiceSearchHandler = function (event) {
    if (!event.target) return false;
    var newLocation = event.target.value;
    window.location.href = newLocation;
  }

  PracticeSearch.on('change', practiceSearchHandler);

});
